import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import Button from '../../../components/button';
import Icon from '../../../components/icon';
import PaymentModal from '../../../components/modals/payment-modal';
import { InlineSpacer } from '../../../components/spacer';
import Spinner from '../../../components/spinner';
import { useDocument } from '../../../context/document-context';
import { useModal } from '../../../hooks/use-modal';

type Props = {
  fullWidth?: boolean;
};
/**
 * Button to initiate payment or download file depending on the document state
 */
export const GetFileButton: React.FC<Props> = ({ fullWidth = false }) => {
  const { state, send } = useDocument();
  const { Modal, openModal } = useModal();

  // default to idle button
  let button = (
    <Button
      variant="solid"
      color="primary"
      fullWidth={fullWidth}
      onClick={() => {
        send('INITIATE_DOCUMENT_PROCESSING');
        send('INITIATE_PAYMENT');
        openModal();
      }}
    >
      Get your edited Word file
    </Button>
  );

  if (state.hasTag('paymentCaptured')) {
    // document is still processing, showing loading button
    if (state.hasTag('documentIsProcessing')) {
      // if (true) {
      button = (
        <LoadingButton
          variant="solid"
          color="primary"
          fullWidth={fullWidth}
          onClick={() => {
            openModal();
          }}
        >
          <Spinner />
          <InlineSpacer size={8} />
          Still generating your file
        </LoadingButton>
      );
    }

    // document processed, show direct download button
    if (state.hasTag('documentDone')) {
      button = (
        <Button
          as="a"
          variant="solid"
          color="primary"
          fullWidth={fullWidth}
          //@ts-ignore
          href={state.context.downloadUrl}
          download
        >
          Download your edited Word file
        </Button>
      );
    }

    // document processing failed, showing failed button
    if (state.hasTag('documentFailed')) {
      button = (
        <Button variant="solid" color="error" fullWidth={fullWidth}>
          <Icon icon={faTimes} />
          <InlineSpacer size={4} />
          Failed to generate file
        </Button>
      );
    }
  }

  // eslint-disable-next-line no-console
  console.error(
    "GetFileButton: Invalid document and payment state, don't know which button to show. Showing Idle button.",
  );

  return (
    <>
      {button}
      <Modal>
        <PaymentModal />
      </Modal>
    </>
  );
};

const LoadingButton = styled(Button)`
  opacity: 0.4;
`;
